import React from "react"
import Layout from "../components/layout"
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'

const useStyles = makeStyles({
    root:{
        textAlign:"center",
        paddingTop: "1rem",
        margin:"auto"
    },
    title: {
        fontSize: 50,

    }

})

const OrderSuccess = () => {

    const classes = useStyles()

  return (
  <Layout>
      <div className={classes.root}>
          <Typography className={classes.title}>Takk for din henvendelse</Typography>
          <Typography>Vi tar kontakt med deg så snart som mulig</Typography>
          <Typography>Du kan nå lukke nettleseren</Typography>
      </div>
  </Layout>)
}

export default OrderSuccess
